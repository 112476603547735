
import * as THREE from 'three'
import Experience from "../Experience";
 

export default class Environment {

    constructor(){
        this.experience = new Experience()
        this.scene = this.experience.scene
        this.resources = this.experience.resources
        this.debug = this.experience.debug

        this.setSunLight()
        this.setEnvironmentMap()

        if(this.debug.active){
            this.debugFolder = this.debug.ui.addFolder('environment')
            }

    }

    setSunLight(){

        const sunLight = new THREE.DirectionalLight('#ffffff', 4)
        sunLight.castShadow = true
        sunLight.shadow.camera.far = 15
        sunLight.shadow.mapSize.set(1024, 1024)
        sunLight.shadow.normalBias = 0.05
        sunLight.position.set(3.5, 2, - 1.25)
        this.scene.add(sunLight)

        const fog = new THREE.Fog('#9df5c1' , 1 , 50)
        this.scene.fog = fog

        if(this.debug.active){

            // this.debugFolder.add(this.sunLight.position, 'x')
            // .name('sunlight')
            // .min(0)
            // .max(10)
            // .step(0.0001)

            this.debugFolder.add(sunLight.position, 'x').max(1).min(0).step(0.002)

        }

      
    }


    setEnvironmentMap(){
        this.environmentMap = {}
        this.environmentMap.intensity = 0.4
        this.environmentMap.texture = this.resources.items.environmentMapTexture
        this.environmentMap.texture.encoding = THREE.sRGBEncoding

        this.scene.environment = this.environmentMap.texture

        this.environmentMap.updateMaterial = () => {

            this.scene.traverse((child) => {
                if(child instanceof THREE.Mesh && child.material instanceof THREE.MeshStandardMaterial){

                    child.material.envMap = this.environmentMap.texture
                    child.material.envMapIntensity = this.environmentMap.intensity
                    child.material.needsUpdate = true 

                }
            })
        }
        
        this.environmentMap.updateMaterial()

        //Debug

        if(this.debug.active){
    //   this.debugFolder.add(this.environmentMap, 'intensity').name('envMapIntensity')
    //         .max(4)
    //         .min(0)
    //         .step(0.0001)
    //         .onChange(this.environmentMap.updateMaterial)
        }

    }

}
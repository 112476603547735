import Experience from "../Experience";
import * as THREE from 'three'

export default class Particles {
    

    constructor(){
        this.experience = new Experience ()
        this.scene =   this.experience.scene
        this.resources = this.experience.resources
        this.time = this.experience.time
        this.debug = this.experience.debug

        const parameters = {
            materialColor: '#ffeded'
        }
        

        this.particlesCount = 2000

        this.particleGeometry = new THREE.BufferGeometry()
        this.particlesMaterial = new THREE.PointsMaterial({
            color: parameters.materialColor,
            sizeAttenuation : true,
            size : 0.03
        })

        this.setupParticles()

    }

    setupParticles(){
        

/**
 * Particles
 */

const particlesCount = 2000
const positions = new Float32Array(particlesCount * 3)
const objectDistance = 4


for(let i = 0 ; i < particlesCount ; i++){
    positions[i * 3 + 0] = (Math.random() - 0.5) * 100
    positions[i * 3 + 1] = objectDistance * 0.4 - Math.random() * objectDistance * 3
    positions[i * 3 + 2] = (Math.random() - 0.5 ) * 100
}

 this.particleGeometry.setAttribute('position' , new THREE.BufferAttribute(positions, 3))



const particles = new THREE.Points(this.particleGeometry, this.particlesMaterial)

this.scene.add(particles)


    }

    update(){
        let elapsedTime = this.time.delta
        for (let i = 0 ; i<this.particlesCount ; i++){
            const i3 = i * 3
            const y = this.particleGeometry.attributes.position.array[i3 + 1] 
            this.particleGeometry.attributes.position.array[i3 + 1] = y + (0.001 * elapsedTime) // Math.sin((elapsedTime * 0.1 ) + x )
            if(this.particleGeometry.attributes.position.array[i3 +1] >= 10){
                this.particleGeometry.attributes.position.array[i3 + 1] = -10
            }
        }
        this.particleGeometry.attributes.position.needsUpdate = true 
    }


}
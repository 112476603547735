import Experience from "../Experience";
import * as THREE from 'three'

export default class Helper {
  
    constructor(){

        this.experience = new Experience()
        this.scene = this.experience.scene
        this.resources = this.experience.resources
        this.sizes = this.experience.sizes

        this.values = {}

        this.values.scrollX = 0
        this.values.scrollY = 0

        this.cursor = {}
        this.cursor.x = 0
        this.cursor.y = 0

        this.values.currentSection = 0
        this.values.lastSection = 0 
        this.setListener()
    }

    setListener(){
        
    window.addEventListener('scroll' , () =>{
        this.values.scrollY = window.scrollY
        //console.log(this.values.scrollY)
        const newSection = Math.round( this.values.scrollY /this.sizes.height)

        if(newSection != this.values.currentSection) {
            this.values.lastSection = this.values.currentSection
            this.values.currentSection = newSection
          //  console.log(this.values.currentSection)
            // gsap.to(
            //     sectionMeshes[currentSection].rotation,
            //     {
            //         duration: 1.5, 
            //         ease: 'power2.inOut',
            //         x: '+=6',
            //         y: '+=3'
            //     }
            // )
        }
    })
    

    window.addEventListener('mousemove', (event) =>{
        this.cursor.x = event.clientX / this.sizes.width - 0.5
         this.cursor.y = event.clientY  / this.sizes.height - 0.5 
     }
     )

    }

}


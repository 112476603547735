import * as THREE from 'three'
import Experience from "./Experience"
import {OrbitControls} from 'three/examples/jsm/controls/OrbitControls'

export default class Camera {

    constructor( ){
        this.experience = new Experience()
        this.sizes = this.experience.sizes
        this.scene = this.experience.scene
        this.canvas = this.experience.canvas
         
        console.log(this)

        this.setInstance()
        this.setOrbitControl()
    }

    setInstance(){
    this.instance  = new THREE.PerspectiveCamera(
    35, this.sizes.width/this.sizes.height, 
    0.1,
    100
    )
    this.instance.position.set(4,4,10)
    this.scene.add(this.instance)
    }

    setOrbitControl(){
        this.controls = new OrbitControls(this.instance, this.canvas)
        this.controls.enableDamping = true 
    }

    resize(){
        this.instance.aspect = this.sizes.width / this.sizes.height
        this.instance.updateProjectionMatrix()
    }

    update(){
        this.controls.update()
     }

}